/* eslint-disable class-methods-use-this */

// Private Outside PnModal class Storage
let instances = [];

export default class PnModal {
  constructor(width, height) {
    // active means if it is open/visible
    this.active = false;
    // zIndex is deprecated will update it because of maybe legacy support
    this.zIndex = 0;
    this.width = width;
    this.height = height;
    this.openElementCache = [];

    // Elements
    this.fg = null;
    this.bg = null;
    this.frame = null;
    this.closebtn = null;

    instances.push(this);
    window.addEventListener('resize', () => this.setPosition());

    this.preparations();
  }

  preparations() {
    this.appendStyles();
    // background Element
    const background = document.createElement('div');

    background.className = 'modalWindowBackground hidden';
    background.style.cssText = 'background-color:rgba(0,0,0,0.5);position:fixed;top:0;left:0;width:100%;height:100%;';
    background.addEventListener('click', () => this.close(true));

    this.bg = background;
    document.documentElement.appendChild(background);

    // Close Button Element
    const closeButton = document.createElement('div');

    closeButton.className = 'modalWindowCloseButton hidden';
    closeButton.style.cssText = 'position:absolute;left:50%';
    closeButton.addEventListener('click', () => this.close(true));

    this.closebtn = closeButton;
    document.documentElement.appendChild(closeButton);

    // Content Element (iFrame)
    const iframeContent = document.createElement('iframe');
    iframeContent.className = 'modalWindow hidden';
    iframeContent.style.cssText = 'background-color:#fff;position:absolute;width:0;height:0;left:50%';

    iframeContent.setAttribute('name', 'modalFrame');
    iframeContent.setAttribute('frameborder', 0);
    iframeContent.setAttribute('scrolling', 'auto');

    this.frame = iframeContent;
    document.documentElement.appendChild(iframeContent);

    // Content Element (not iFrame)
    const content = document.createElement('div');
    content.className = 'modalWindow hidden';
    content.style.cssText = 'background-color:#fff;position:absolute;overflow:auto;left:50%';

    this.fg = content;
    document.documentElement.appendChild(content);
  }

  fixBody() {
    document.documentElement.classList.add('modal-engaged');
  }

  unfixBody() {
    if (!instances.find((item) => item.active)) {
      document.documentElement.classList.remove('modal-engaged');
    }
  }

  appendStyles() {
    const styles = document.createElement('style');
    styles.innerText = '.hidden { display: none } .modal-engaged { overflow: hidden; }';
    document.head.appendChild(styles);
  }

  currentViewportCenter() {
    return {
      x: window.innerWidth / 2 + document.documentElement.scrollLeft,
      y: window.innerHeight / 2 + document.documentElement.scrollTop,
    };
  }

  onClose() {
    // placeholder method
  }

  /**
    * Aktyvuoja ar deaktyvuoja langa
    * on - true | false
  */
  focus(condition) {
    if (typeof condition !== 'boolean') {
      return this;
    }

    let previousPosition;

    if (condition) {
      previousPosition = instances.indexOf(this);

      // move current instance to the end
      instances = instances.concat(
        instances.splice(instances.indexOf(this), 1),
      );
    } else {
      // move current instance to the previous location;\
      instances.splice(
        previousPosition,
        0,
        instances.splice(instances.indexOf(this), 1)[0],
      );
    }

    // recalculate and apply zIndex to css;
    instances.forEach((item, index) => {
      instances[index].zIndex = index;
      instances[index].bg.style.zIndex = 100 + 100 * index;
      instances[index].fg.style.zIndex = 102 + 100 * index;
      instances[index].frame.style.zIndex = 104 + 100 * index;
      instances[index].closebtn.style.zIndex = 114 + 100 * index;
    });

    return this;
  }

  /*
    * Privati. Centruoja modalini langa (nustato jo pozicija)
    *
  */
  setPosition(el) {
    let fg = el;
    if (!this.active) return this;

    if (fg === undefined) {
      if (!this.fg.classList.contains('hidden')) {
        fg = this.fg;
      } else if (this.frame.classList.contains('hidden')) {
        fg = this.frame;
      }
    }

    // if still undefined
    if (fg === undefined) return this;

    setTimeout(() => {
      let { height } = this;
      const width = this.width
        ? Math.min(this.width, window.innerWidth)
        : window.innerWidth;

      const find = this.fg.querySelector('.modalWindowContent');

      if (!this.height && find !== null) {
        fg.style.width = width;
        fg.style.height = 20;
        height = Math.min(
          1 + fg.scrollHeight,
          parseInt(window.innerHeight * 0.7, 10),
        );
      }

      const top = Math.floor(
        Math.max(0, this.currentViewportCenter().y - height / 2),
      );

      fg.style.width = `${width}px`;
      fg.style.height = `${height}px`;
      fg.style.marginLeft = `${Math.floor(-width / 2)}px`;
      fg.style.top = `${top}px`;

      this.closebtn.style.marginLeft = `${Math.floor(-this.closebtn.offsetWidth + width / 2)}px`;
      this.closebtn.style.top = `${top}px`;
    });

    return this;
  }

  /**
    * Atidaro ir padaro visible div langa
  */
  open(html) {
    this.close();
    this.active = true;
    this.focus(true);

    const container = document.createElement('div');
    container.classList.add('modalWindowContent');
    container.innerHTML = html;
    this.fg.appendChild(container);

    this.setPosition(this.fg);

    this.bg.classList.remove('hidden');
    this.fg.classList.remove('hidden');
    this.closebtn.classList.remove('hidden');

    this.fixBody();

    if (!this.height) this.setPosition(this.fg);

    return this;
  }

  /**
    * Uzdaro atidaryta modal langa (nesvarbu ar div ar frame)
    *
  * */
  close(userAction) {
    if (userAction) this.onClose();
    this.active = false;
    this.bg.classList.add('hidden');
    this.fg.classList.add('hidden');
    this.frame.classList.add('hidden');
    this.closebtn.classList.add('hidden');
    this.fg.innerHTML = '';
    this.unfixBody();
    this.focus(false);

    return this;
  }

  /**
    * Parodo modalini langa "Loading..."
    *
  */
  loading() {
    this.open(
      '<div style="min-height:100px"><div class="window-loading">Loading...</div></div>',
    );
    return this;
  }

  /**
    * Pagal "jquerio" selektoriu paima elementa ir ji rodo
  */
  openElement(query) {
    const el = document.querySelector(query);

    if (typeof this.openElementCache[query] === 'undefined') {
      this.openElementCache[query] = el.innerHTML;
      el.parentNode.removeChild(el);
    }

    return this.open(this.openElementCache[query]);
  }

  openURL(url, width, height) {
    if (!url) return;
    if (width && width > 0) this.width = width;
    if (height && height > 0) this.height = height;

    this.close();
    this.active = true;
    this.loading();

    const handler = () => {
      this.close();
      this.focus(true);
      this.active = true;

      this.bg.classList.remove('hidden');
      this.frame.classList.remove('hidden');
      this.closebtn.classList.remove('hidden');

      this.setPosition(this.frame);
      this.fixBody();
      this.frame.removeEventListener('load', handler);
    };

    this.frame.addEventListener('load', handler, true);
    this.frame.src = url;
  }

  disableClickOnBg() {
    const newbg = this.bg.cloneNode(true);
    this.bg.parentNode.replaceChild(newbg, this.bg);
    this.bg = newbg;
  }

  /**
    * Pakeicia lnago matmenis
    *
  * */
  resizeTo(width, height, remember) {
    const was = {
      w: this.width,
      h: this.height,
    };

    if (width && width > 0) this.width = width;
    if (typeof height === 'number') this.height = height;

    this.setPosition();
    if (typeof remember === 'undefined' || !remember) {
      // atstatom kas buvo
      this.width = was.w;
      this.height = was.h;
    }

    return this;
  }
}
